<template>
  <div>
    <a class="come-back" @click.prevent="$router.go(-1)"><div></div>Вернуться назад</a>
    <h2 class="title_categories">
      {{ isEdit ? `Редактирование фомирование общественного советова №${$route.params.id}` : 'Создание фомирование общественного совета' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createBlogAdmin" ref="createBlogAdmin">
        <div>
          <div class="content-sb">
            <p>Дата<span>*</span></p>
            <input required placeholder="Введите дату" v-model="dataForm.date" type="datetime-local" name="date_time" class="input blue">
          </div>

          <div class="content-sb">
            <p>Заголовок<span>*</span></p>
            <input required v-model="dataForm.title" placeholder="Введите заголовок" type="text" name="name" class="input blue">
          </div>

          <div class="content-sb">
            <p>Описание<span>*</span></p>
            <!-- <ckeditor :editor-url="$root.editorUrl" v-model="dataForm.description" required></ckeditor> -->
            <input required v-model="dataForm.description" placeholder="Введите описание" type="text" name="name" class="input blue">
          </div>

          <div class="content-sb">
            <p>Текст публикации<span>*</span></p>
            <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="dataForm.text" required></ckeditor>
          </div>
        </div>

        <footer class="content-sb btns-create-form">
          <router-link to="/admin/blogs" class="btn-white">Отмена</router-link>
          <input type="submit" class="btn-blue" value="Сохранить"/>
        </footer>
    </form>

  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
export default {
  name: 'CreateFormationCouncils',
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      member: '',
      dataForm: {
        title: '',
        text: '',
        description: '',
        date: ''
      }
    }
  },
  mounted () {
    if (this.isEdit) {
      this.$store.dispatch('setDetailedFormation', this.$route.params.id)
    }
  },
  watch: {
    '$store.getters.getDetailedFormation' () {
      const dataFromServer = this.$store.getters.getDetailedFormation

      this.dataForm = {
        title: dataFromServer.title,
        text: dataFromServer.text,
        description: dataFromServer.description,
        date: (dataFromServer.date + ' 00:00:00').split(' ').join('T')
      }
      this.$forceUpdate()
    }
  },
  methods: {
    createBlogAdmin () {
      const formData = new FormData()

      for (const key in this.dataForm) {
        var item = this.dataForm[key]
        formData.append(key, item)
      }
      if (!this.isEdit) {
        this.$store.dispatch('createFormationAdmin', formData)
      } else {
        this.$store.dispatch('updateFormationAdmin', [formData, this.$route.params.id])
      }
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
.select, .input{
  width: 33.75rem;
}
.content-sb{
  margin-bottom: 1.25rem;
  span{
    color: red;
  }
}
.wrapper-blog{
  margin-top: 1.25rem;
}
.btns-create-form{
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}
</style>
